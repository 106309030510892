import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBolt,
  cilContact,
  cilDescription,
  // cilGamepad,
  cilInbox,
  cilLoop,
  cilPeople,
  cilSettings,
  cilExitToApp,
  cilGamepad,
  cilSearch,
  // cilSpeedometer,
  cilTruck,
} from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react-pro'

const _nav = [
  {
    component: CNavTitle,
    name: 'magazyn',
    access: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_ACCOUNT_MANAGER'],
  },
  {
    component: CNavItem,
    name: 'kontrahenci',
    to: '/kontrahenci',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    access: ['ROLE_USER', 'ROLE_ACCOUNT_MANAGER'],
  },
  {
    component: CNavItem,
    name: 'produkty',
    to: '/produkty',
    icon: <CIcon icon={cilGamepad} customClassName="nav-icon" />,
    access: ['ROLE_USER', 'ROLE_ACCOUNT_MANAGER'],
  },
  {
    component: CNavItem,
    name: 'Zewnętrzne produkty',
    to: '/zewnetrzne-produkty',
    icon: <CIcon icon={cilExitToApp} customClassName="nav-icon" />,
    access: ['ROLE_ADMIN'],
  },
  {
    component: CNavItem,
    name: 'Wyszukiwarka',
    to: '/kody',
    icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
    access: ['ROLE_ADMIN'],
  },
  {
    component: CNavItem,
    name: 'reklamacje',
    to: '/reklamacje',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    access: ['ROLE_USER'],
  },
  {
    component: CNavTitle,
    name: 'operacje',
    access: ['ROLE_USER'],
  },
  {
    component: CNavItem,
    name: 'zamówienia',
    to: '/zamowienia',
    icon: <CIcon icon={cilInbox} customClassName="nav-icon" />,
    access: ['ROLE_USER', 'ROLE_ACCOUNT_MANAGER'],
  },
  {
    component: CNavItem,
    name: 'dostawy',
    to: '/dostawy',
    icon: <CIcon icon={cilTruck} customClassName="nav-icon" />,
    access: ['ROLE_USER', 'ROLE_ACCOUNT_MANAGER'],
  },
  {
    component: CNavTitle,
    name: 'oferty',
    access: ['ROLE_USER'],
  },
  {
    component: CNavItem,
    name: 'flash',
    to: '/flash',
    icon: <CIcon icon={cilBolt} customClassName="nav-icon" />,
    access: ['ROLE_USER'],
  },
  {
    component: CNavItem,
    name: 'dzienne',
    to: '/dzienne',
    icon: <CIcon icon={cilLoop} customClassName="nav-icon" />,
    access: ['ROLE_ADMIN'],
  },
  {
    component: CNavTitle,
    name: 'zespół',
    access: ['ROLE_ADMIN'],
  },
  {
    component: CNavItem,
    name: 'użytkownicy',
    to: '/uzytkownicy',
    icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
    access: ['ROLE_ADMIN'],
  },
  {
    component: CNavTitle,
    name: 'ustawienia',
    access: ['ROLE_ADMIN'],
  },
  {
    component: CNavItem,
    name: 'ustawienia',
    to: '/ustawienia',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    access: ['ROLE_ADMIN'],
    // badge: {
    //   color: 'danger',
    //   text: 'BRAK',
    // },
  },
]

export default _nav
