const listFields = [
  {
    id: 'name',
    category: 'contractor',
    label: 'Dostawca',
    placeholder: '-',
  },
  {
    id: 'status',
    label: 'Status',
    placeholder: '-',
    component: {
      type: 'badge',
      param: 'delivery',
    },
  },
  {
    id: 'invoiceNumber',
    label: 'Numer faktury',
    placeholder: '-',
  },
  {
    id: 'dateOfOrder',
    label: 'Data dostawy',
    placeholder: '-',
    format: 'ddmmyyyy',
  },
  {
    id: 'value',
    label: 'Wartość dostawy',
    placeholder: '-',
    format: 'zzgg',
    suffix: 'EUR',
  },
  {
    id: 'valueByCurrency',
    label: 'Wartość dostawy w walucie',
    placeholder: '-',
    format: 'zzgg',
  },
  {
    id: 'currency',
    label: 'Waluta',
    placeholder: '-',
  },
  {
    id: 'source',
    label: 'Źródło dostawy',
    placeholder: '-',
  },
  {
    id: 'name',
    category: 'user',
    label: 'Autor',
    placeholder: '-',
  },
  {
    id: 'isPaid',
    label: 'Opłacone',
    placeholder: '-',
    component: {
      type: 'badge',
      param: 'isPaid',
    },
  },
  {},
  {
    id: 'name',
    category: 'supervisor',
    label: 'OPIEKUN',
    placeholder: '-',
  },
]

export default listFields
