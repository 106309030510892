import { parseObjectToUrlQuery } from 'src/helpers'
import axios from '../../axios'
import { notifyError, notifySucces } from '../notify'

const ProductsService = {
  getProducts: (params) => async () => {
    return await axios
      .get(`/api/products${parseObjectToUrlQuery(params)}`)
      .then((res) => {
        console.log('@GetProducts data:', res.data)
        // notifySucces()
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  getProductsList:
    (params = null) =>
    async () => {
      // const fields = ['id', 'name', 'codesInPhoto', 'charsInCode']

      console.log(
        '@GetProductList, filter by',
        `/api/products/list/${parseObjectToUrlQuery(params)}`
      )

      return await axios
        .get(`/api/products/list/${parseObjectToUrlQuery(params)}`)
        .then((res) => {
          console.log('@getProductsList res data', res.data)
          // notifySucces()
          return res.data
        })
        .catch((e) => {
          notifyError(e)
          throw e
        })
    },

  getProduct: (id) => async () => {
    return await axios
      .get(`/api/products/${id}`)
      .then((res) => {
        console.log('@getProuduct res data: ', res.data)
        return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  addProduct: async (data) => {
    console.log(data)
    return await axios
      .post('/api/products', data)
      .then((res) => {
        console.log(res.data)
        notifySucces('Produkt został dodany')
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  editProduct: (id) => async (data) => {
    console.log('editProduct payload', data)
    return await axios
      .put(`/api/products/${id}`, data)
      .then((res) => {
        console.log('saved data', res.data)
        notifySucces('Produkt został zmieniony')
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  getProductsStats: (params) => async () => {
    return await axios
      .get(`/api/products/stats${parseObjectToUrlQuery(params)}`)
      .then((res) => {
        console.log('@GetProductsStats data:', res.data)
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  getProductsCsv: (params) => async () => {
    return await axios
      .get(`/api/products/csv${parseObjectToUrlQuery(params)}`)
      .then((res) => {
        console.log('@GetProductsCSV res:', res)
        // notifySucces()
        return res
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  updateEcommerceStatus: (id) => async (value) => {
    return await axios
      .patch(`/api/products/${id}/available-for-sale`, value)
      .then((res) => {
        return res
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },

  searchCode: (code) => async () => {
    return await axios
      .get(`/api/codes/${code}/search`)
      .then((res) => {
        console.log('@searchCode res data: ', res.data)
        return res.data.data
      })
      .catch((e) => {
        if (e.response.status !== 404) {
          notifyError(e)
          throw e
        }
      })
  },
}

export default ProductsService
