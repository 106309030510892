import React from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import DisplayNews from '../../components/DisplayNews'
import { useQuery } from 'react-query'
import DataService from '../../services/DataService'
import { searchCodeTable } from './searchCodeTable'
import { useNavigate } from 'react-router-dom'

const Searcher = () => {
  const [searchCode, setSearchCode] = React.useState('')
  const navigate = useNavigate()

  const { data, isLoading, refetch, isError } = useQuery(
    ['search-code'],
    DataService.products.searchCode(searchCode),
    {
      keepPreviousData: false,
      enabled: false,
    }
  )

  return (
    <>
      <CContainer fluid>
        <DisplayNews />
        <CRow>
          <CCol className="d-flex justify-content-between pb-4">
            <div className="d-flex flex-row">
              <h2>Wyszukaj kod</h2>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg={12}>
            <CCard className="shadow-none">
              <CCardBody>
                <CInputGroup
                  className="d-flex gap-5 align-items-center mb-3 mt-3"
                  style={{ width: '500px' }}
                >
                  <CFormLabel className="mt-1">Wpisz kod:</CFormLabel>
                  <div className="d-flex flex-grow-1">
                    <CFormInput
                      placeholder="Wpisz pełen kod którego szukasz"
                      value={searchCode}
                      className="w-100"
                      onChange={(e) => setSearchCode(e.target.value)}
                    />
                    <CButton onClick={refetch}>Wyszukaj</CButton>
                  </div>
                </CInputGroup>
                <CSmartTable
                  loading={isLoading}
                  items={searchCode.length === 0 || isError ? [] : data}
                  itemsPerPage={100}
                  {...searchCodeTable(navigate)}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
export default Searcher
