const formFieldsDetailsEdit = [
  [
    {
      id: 'invoiceNumber',
      category: '',
      label: 'Numer FV',
      placeholder: '',
      type: 'text',
      required: false,
    },
    {
      id: 'dateOfOrder',
      category: '',
      label: 'Data dostawy',
      placeholder: 'np. FIFA',
      type: 'date',
      required: true,
    },
    {
      id: 'isPaid',
      category: '',
      label: 'Status opłaty',
      placeholder: '---',
      type: [
        [false, 'NIEOPŁACONE'],
        [true, 'OPŁACONE'],
      ],
      required: true,
    },
    {
      id: 'note',
      category: '',
      label: 'Uwagi',
      placeholder: '',
      type: 'textarea',
      required: false,
      rows: 4,
    },
  ],
]

export default formFieldsDetailsEdit
