import React from 'react'
import { navigationStylingProps } from 'src/assets/custom/tableSorting'
import { CFormInput } from '@coreui/react-pro'

const columns = [
  {
    key: 'name',
    label: 'Produkt',
    _style: { minWidth: '60px' },
  },
  {
    key: 'expectedQuantity',
    label: 'Ilość',
    _style: { minWidth: '110px' },
  },
  {
    key: 'purchasePriceByCurrency',
    label: 'Cena zakupu w walucie',
    _style: { minWidth: '50px' },
  },
]

export const tableProps = (currency, register, setValue) => {
  return {
    columns: columns,
    scopedColumns: {
      name: (data) => <td>{data['product.name']}</td>,
      expectedQuantity: ({ expectedQuantity }, index) => {
        return (
          <td>
            <CFormInput
              {...register(`products[${index}].expectedQuantity`)}
              defaultValue={expectedQuantity}
              type={'number'}
              onChange={(e) => {
                console.log(Number(e.target.value))
                setValue(`products[${index}].expectedQuantity`, Number(e.target.value))
              }}
            />
          </td>
        )
      },
      purchasePriceByCurrency: ({ purchasePriceByCurrency }, index) => {
        return (
          <td>
            <CFormInput
              {...register(`products[${index}].purchasePriceByCurrency`)}
              defaultValue={purchasePriceByCurrency}
              type={'number'}
              onChange={(e) =>
                setValue(`products[${index}].purchasePriceByCurrency`, Number(e.target.value))
              }
            />
          </td>
        )
      },
    },
    clickableRows: false,
    columnSorter: true,
    pagination: true,
    itemsPerPageSelect: false,
    itemsPerPage: 100,
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}
