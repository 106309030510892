import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CFormLabel,
  CRow,
  CSmartTable,
} from '@coreui/react-pro'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import DataService from 'src/services/DataService'
import IsLoading from 'src/components/IsLoading'
import { notifyError, notifySucces } from 'src/services/notify'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import { flattenDataStructure, formatPriceToDisplayDecimals } from 'src/helpers'
import { useStoreState } from 'easy-peasy'
import BasicForm from 'src/components/BasicForm'
import formFieldsDetailsEdit from './DeliverFormDetailsEdit'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'
import { tableProps } from './DeliveryEditDetailsProductTable.js'

const DeliveryEditDetails = () => {
  const { id } = useParams()
  const [isLoading] = useState(false)
  const isSuper = useStoreState((store) => store.auth.isSuper)
  const [detailsLoading, setDetailsLoading] = useState(false)

  const navigate = useNavigate()

  const {
    data,
    error,
    isLoading: deliveryDataIsLoading,
    isFetching,
    refetch,
    isRefetching,
  } = useQuery(['delivery', id], DataService.deliveries.getDelivery(id))

  const { register, watch, handleSubmit, setValue, reset } = useForm({
    values: {
      invoiceNumber: data?.invoiceNumber || '',
      isPaid: data?.isPaid || false,
      note: data?.note || '',
      dateOfOrder: data?.dateOfOrder || '',
      products:
        data?.products.map((el) => ({
          id: el.id,
          expectedQuantity: el.expectedQuantity,
          purchasePriceByCurrency: el.purchasePriceByCurrency,
        })) || [],
    },
  })

  useEffect(() => {
    if (data) {
      //reformat price value on data change
      data.products.forEach((product, index) => {
        setValue(
          `products[${index}].purchasePriceByCurrency`,
          formatPriceToDisplayDecimals(product.purchasePriceByCurrency)
        )
      })
      //reformat price value on data initialization
      setValue(`dateOfOrder`, data.dateOfOrder.split('T')[0])
    }
  }, [data, setValue, isRefetching])

  const { mutate: mutateDetails } = useMutation(DataService.deliveries.updateDetails(id), {
    onMutate: () => {
      setDetailsLoading(true)
    },
    onSettled: () => {
      setDetailsLoading(false)
      reset()
    },
    onSuccess: () => {
      notifySucces('Dostawa została zaktualizowana')
      refetch()
    },
    onError: () => notifyError('błąd'),
  })

  const onHandleSubmit = (formData) => {
    formData.products.forEach((product) => {
      product.expectedQuantity = Number(product.expectedQuantity)
      product.purchasePriceByCurrency = Number(product.purchasePriceByCurrency * 100)
    })

    mutateDetails(formData)
  }

  if (deliveryDataIsLoading) return <IsLoading />
  if (!data) {
    console.log(error)
    return <span>missing data</span>
  }

  if (isSuper)
    return (
      <>
        <CContainer fluid className="h-100">
          {(isLoading || detailsLoading || isRefetching) && <IsLoadingOverlay />}
          <CRow>
            <CCol className="d-flex flex-row justify-content-between pb-4">
              <h2>Edytuj szczegóły dostawy JK-DELIVERY-{String(id).padStart(5, '0')}</h2>
              <div>
                <EditButton label="Zapisz" onClick={handleSubmit((data) => onHandleSubmit(data))} />
                <CancelButton label="Anuluj" onClick={() => navigate(`/dostawa/${id}`)} />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg={3}>
              <CCard>
                <CCardHeader>Dane Dostawy</CCardHeader>
                <CCardBody>
                  <CRow className="mb-3">
                    <CFormLabel>Klient</CFormLabel>
                    <div className="fs-5 mb-3 fw-semibold">{data.contractor.name}</div>
                    <BasicForm
                      formFields={formFieldsDetailsEdit}
                      // onSubmit={handleSubmit((data) => onSubmitSave(data))}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      disabled={false}
                      className="mb-3"
                    />
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol lg={9}>
              <CCard>
                <CCardHeader>Produkty</CCardHeader>
                <CCardBody>
                  <CSmartTable
                    items={flattenDataStructure(data?.products)}
                    loading={isFetching}
                    {...tableProps(data.currency, register, setValue)}
                  />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </>
    )
  else return <></>
}

export default DeliveryEditDetails
