import { convertStringToBoolean, parseObjectToUrlQuery } from 'src/helpers'
import axios from '../../axios'
import { notifyError, notifySucces } from '../notify'

const DeliveriesService = {
  getDeliveries: (params) => async () => {
    const startTime = new Date()

    console.log(
      '@GetDeliveries, filter by',
      `/api/deliveries${parseObjectToUrlQuery(convertStringToBoolean(params))}`
    )
    return await axios
      .get(`/api/deliveries${parseObjectToUrlQuery(convertStringToBoolean(params))}`)
      .then((res) => {
        const duration = new Date() - startTime
        console.log(
          '@GetDeliveries, response time:',
          (duration / 1000).toFixed(1),
          's, data:',
          res.data
        )
        // notifySucces()
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  //same as above, separated for clarity
  getPartnerDeliveries: (params) => async () => {
    console.log(
      '@GetDeliveries, filter by',
      `/api/deliveries${parseObjectToUrlQuery(convertStringToBoolean(params))}`
    )
    return await axios
      .get(`/api/deliveries${parseObjectToUrlQuery(convertStringToBoolean(params))}`)
      .then((res) => {
        console.log('@GetDeliveries data:', res.data)
        // notifySucces()
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  getDelivery: (id) => async () => {
    return await axios
      .get(`/api/deliveries/${id}`)
      .then((res) => {
        console.log('@delivery data, ', res.data)
        return res.data.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  addDelivery: async (data) => {
    console.log(data)
    return await axios
      .post('/api/deliveries', data)
      .then((res) => {
        console.log(res.data)
        notifySucces('Nowa dostawa została dodana')
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  addProductContainer:
    () =>
    async ({ formData, deliveryId, isVerifying }) => {
      console.log(
        '@sending productContainer request for delivery',
        deliveryId,
        ', reqdata:',
        formData
      )
      return await axios
        .post(`/api/deliveries/${deliveryId}/add-product`, formData)
        .then((res) => {
          console.log('@addProductContainer response: ', res)
          return {
            deliveryProductId: res.data.data.id,
            productName: res.data.data.name.name,
          }
          // return res.data
        })
        .catch((e) => {
          console.log(e)
          notifyError(e)
          // throw e
        })
    },
  editProductContainer:
    () =>
    async ({ formData, productContainerId }) => {
      console.log('@editing productContainer, id: ', productContainerId, ' , reqdata:', formData)
      return await axios
        .put(`/api/deliveries-products/${productContainerId}`, formData)
        .then((res) => {
          console.log('@editProductContainer response, ', res)
        })
        .catch((e) => {
          console.log(e)
          notifyError(e)
          throw e
        })
    },
  verifyDeliveryCodes2:
    () =>
    async ({ productId, data }) => {
      console.log('@sending codes to verify: ', `/api/codes-validate/${productId}`, data)
      return await axios
        .post(`/api/codes-validate/${productId}`, data)
        .then((res) => {
          return res.data
        })
        .catch((e) => {
          if (e.response.status !== 422) notifyError(e)
          if (e.response.status === 422) return e.response.data
        })
    },
  addDeliveryCodes:
    () =>
    async ({ deliveryId, deliveryProductId, data }) => {
      console.log(
        '@sending codes to save: ',
        `/api/deliveries/${deliveryId}/codes/${deliveryProductId}`,
        data
      )
      return await axios
        .post(`/api/deliveries/${deliveryId}/codes/${deliveryProductId}`, data)
        .then((res) => {
          console.log('addDeliveryCodes response: ', res.data)
          return res.data
          // return res.data
        })
        .catch((e) => {
          console.log(e)
          notifyError(e)
          // throw e
        })
    },
  addDeliveryCodes2:
    () =>
    async ({ data }) => {
      console.log('@sending codes to save: ', `/api/deliveries-all`, convertStringToBoolean(data))
      return await axios
        .post(`/api/deliveries-all`, convertStringToBoolean(data))
        .then((res) => {
          console.log('Delivery response', res.data.data)
          notifySucces('Nowa dostawa została dodana')
          return { res: res.data, deliveryId: res.data.data.id, sentData: data.products }
        })
        .catch((e) => {
          console.log(e)
          notifyError(e)
          // throw e
        })
    },
  addDeliveryFiles2:
    () =>
    async ({ data, deliveryId, productId }) => {
      console.log(
        '@sending files to save: ',
        `/api/deliveries/${deliveryId}/codes-in-file/${productId}`,
        data
      )
      // return new Promise((resolve, reject) => {
      //   resolve('dummy fetching')
      // })
      //właściwa treść
      return await axios
        .post(`/api/deliveries/${deliveryId}/codes-in-file/${productId}`, data)
        .then((res) => {
          console.log(res)
          // notifySucces('Plik został dodany')
          return {
            res: res.data,
            productId: productId,
            name: data.name,
            sentCodesCount: data.codesInFile,
          }
        })
        .catch((e) => {
          console.log(e)
          notifyError(e)
          return { res: e.res.data, productId: productId, name: data.name }
          // throw e
        })
    },
  addDeliveryLock2:
    () =>
    async ({ deliveryId }) => {
      console.log('@sending codes to save: ', `/api/deliveries/${deliveryId}/lock`)
      // return new Promise((resolve, reject) => {
      //   resolve('dummy fetching')
      // })
      //właściwa treść
      return await axios
        .post(`/api/deliveries/${deliveryId}/lock`)
        .then((res) => {
          notifySucces('Dostawa została zamknięta')
          return res.data
        })
        .catch((e) => {
          console.log(e)
          notifyError(e)
          // throw e
        })
    },
  editDelivery: (id) => async (data) => {
    return await axios
      .put(`/api/deliveries/${id}`, data)
      .then((res) => {
        console.log(res.data)
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  updateDetails: (id) => async (data) => {
    return await axios
      .put(`/api/deliveries/${id}`, data)
      .then((res) => {
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  getProductDeliveries: (params) => async () => {
    return await axios
      .get(`/api/products-deliveries/${params.id}/${parseObjectToUrlQuery(params)}`)
      .then((res) => {
        console.log('@getProductDeliveries res data:', res.data)
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  getCodesOfProductInDelivery: (deliveryProductId) => async () => {
    console.log('@GetCodesProductInDelivery: ', `/api/codes/${deliveryProductId}/delivery-product`)
    return await axios
      .get(`/api/codes/${deliveryProductId}/delivery-product`)
      .then((res) => {
        console.log('@GetCodesOfProductInDelivery res data', res.data)
        return res.data
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  editCodeOfProductInDelivery: (id) => async (data) => {
    console.log('@PATCH code id: ', id)
    return await axios
      .patch(`/api/codes/${id}`, data)
      .then((res) => {
        console.log(res)
        notifySucces('Kod został zmieniony')
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  deleteCodeOfProductInDelivery: () => async (data) => {
    console.log('@DELETE codes data: ', data.codeIds)
    return await axios
      .delete(`/api/codes`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
      .then((res) => {
        console.log(res)
        notifySucces('Kod został usunięty')
      })
      .catch((e) => {
        notifyError(e)
        throw e
      })
  },
  retailSettings: (deliveryId, deliveryProductId) => async (data) => {
    return await axios
      .patch(
        `/api/deliveries/${deliveryId}/delivery-product/${deliveryProductId}/retail-settings`,
        data
      )
      .then((res) => {
        console.log('@retailSettings data:', res.data)
        return res.data
      })
  },
}

export default DeliveriesService
