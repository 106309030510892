import React, { useState } from 'react'
import {
  CCard,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
  CSmartTable,
  CCardBody,
} from '@coreui/react-pro'
import { useMutation, useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { useNavigate, useParams } from 'react-router-dom'
import BasicList from 'src/components/BasicList'

import listFields from './OrderList'
import { tableProps } from './OrderProductTable'
import EditButton from 'src/components/EditButton'
import HistoryList from '../../components/HistoryList'
import IsLoading from 'src/components/IsLoading'
import { notifyError, notifySucces } from 'src/services/notify'
import CancelButton from 'src/components/CancelButton'
import OrderPassModal from './OrderPassModal'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import { countCodesInOrder, downloadFileFromBlob, useHasAccess } from 'src/helpers'
import { useStoreState } from 'easy-peasy'
import DropdownButton from '../../components/DropdownButton'

const Order = () => {
  const { id } = useParams()
  const [details, setDetails] = useState([]) //for expandable table

  const [manualIsLoading, setManualIsLoading] = useState(false)

  const [passModalVisible, setPassModalVisible] = useState(false)
  const [passData, setPassData] = useState()

  const isSuper = useStoreState((store) => store.auth.isSuper)

  const { data, isError, error, isLoading, isFetching, refetch, isRefetching } = useQuery(
    ['order', id],
    DataService.orders.getOrder(id)
  )

  const { mutate: receiveCodesMutation } = useMutation(DataService.orders.receiveCodes(), {
    onSettled: () => {
      setManualIsLoading(false)
    },
    onSuccess: (res) => {
      setManualIsLoading(false)
      console.log('id of order:', id)
      downloadFileFromBlob(res.data, `JK-ORDER-${String(id).padStart(5, '0')}.zip`)
    },
    onError: (e) => {
      console.error(e)
    },
  })

  const { mutate: generatePasswordMutation } = useMutation(DataService.orders.getPassword(id), {
    onSuccess: (res) => {
      setPassData(res)
      setPassModalVisible(true)
      receiveCodesMutation(res)
    },
    onError: (e) => {
      setManualIsLoading(false)
      if (e?.response?.status === 422) {
        notifyError('Nie ma kodów, które nie zostały jeszcze pobrane.')
      } else {
        notifyError(`Pobieranie zakończone niepowodzeniem. ${e?.response?.data?.error}`)
      }
    },
  })

  const { mutate: sendMailWithCodesMutation } = useMutation(
    DataService.orders.sendMailWithCodes(id),
    {
      onSuccess: (data) => {
        setManualIsLoading(false)
        notifySucces('Mail z kodami został wysłany do Klienta')
        setPassData(data)
        setPassModalVisible(true)
      },
      onError: (e) => {
        console.log({ e })
        setManualIsLoading(false)
        if (e?.response?.status === 422) {
          notifyError('Nie ma kodów, które nie zostały jeszcze wysłane.')
        } else {
          notifyError(`Wysyłanie zakończone niepowodzeniem. ${e?.response?.data?.error}`)
        }
      },
    }
  )

  const { mutate: markAsPaid } = useMutation(DataService.orders.isPaid(id), {
    onSuccess: () => {
      setManualIsLoading(false)
      refetch()
    },
  })

  const { mutate: markAsCanceled } = useMutation(DataService.orders.isCanceled(id), {
    onSuccess: () => {
      setManualIsLoading(false)
      refetch()
    },
  })

  const handleReciveCodes = (allcodes = false) => {
    setPassData()
    generatePasswordMutation({ downloadOnlyNotReceived: !allcodes })
    setManualIsLoading(true)
  }

  const handleSendMailWithCodes = (allcodes = false) => {
    setPassData()
    console.log('handlesendMailWithCodes')
    setManualIsLoading(true)
    sendMailWithCodesMutation({ downloadOnlyNotReceived: !allcodes })
  }

  const handleMarkAsPaid = () => {
    setManualIsLoading(true)
    markAsPaid({ isPaid: true })
  }

  const handleMarkAsNotPaid = () => {
    setManualIsLoading(true)
    markAsPaid({ isPaid: false })
  }

  const handleMarkAsCanceled = () => {
    markAsCanceled({ status: 'ANULOWANE' })
  }

  const navigate = useNavigate()

  const handleEditDetails = () => {
    navigate(`/zamowienie/${data.id}/edit-details`)
  }

  const _hasRestictedAccess = useHasAccess(['ROLE_ADMIN'])

  if (isLoading) return <IsLoading />

  if (isError) {
    console.log(error)
    return <span>bląd</span>
  }

  const isFull = () => {
    return !data.products.some((product) => {
      return product.codes < product.expectedQuantity
    })
  }

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4" md={3}>
            <h2>Zamówiene JK-ORDER-{String(data?.id).padStart(5, '0')}</h2>
          </CCol>
          <CCol md={9} className="d-flex flex-row justify-content-end">
            <div>
              {data.status !== 'ANULOWANE' && countCodesInOrder(data) === 0 && (
                <CancelButton label={'Oznacz jako anulowane'} onClick={handleMarkAsCanceled} />
              )}
              {!data.isPaid && (
                <EditButton label={'Oznacz jako zapłacone'} onClick={handleMarkAsPaid} sm={true} />
              )}
              {data.isPaid && (
                <CancelButton label={'Oznacz jako niezapłacone'} onClick={handleMarkAsNotPaid} />
              )}
              {data.status !== 'ANULOWANE' && !isFull() && (
                <EditButton
                  label={'Przypisz kody do zamówienia'}
                  onClick={() => {
                    navigate(`/zamowienie/${data.id}/edit`)
                  }}
                  sm={true}
                />
              )}
              {data.value > 0 && data.status !== 'ANULOWANE' && (
                <>
                  <DropdownButton
                    label={'Wyślij kody mailem'}
                    buttons={[
                      {
                        label: 'Wyślij wszystkie kody',
                        onClick: () => handleSendMailWithCodes(true),
                      },
                      {
                        label: 'Wyślij wszystkie niewysłane',
                        onClick: () => handleSendMailWithCodes(false),
                      },
                    ]}
                  />
                  {_hasRestictedAccess && (
                    <DropdownButton
                      label={'Pobierz kody'}
                      buttons={[
                        { label: 'Pobierz wszystkie kody', onClick: () => handleReciveCodes(true) },
                        {
                          label: 'Pobierz wszystkie niewysłane',
                          onClick: () => handleReciveCodes(false),
                        },
                      ]}
                    />
                  )}
                </>
              )}
              {isSuper && (
                <CancelButton label={'Edytuj dane szczegółowe'} onClick={handleEditDetails} />
              )}
            </div>
          </CCol>
        </CRow>
        <CRow>
          {(manualIsLoading || isRefetching) && <IsLoadingOverlay />}
          <CCol lg={3}>
            <CCard>
              <CCardHeader>Dane Zamówienia</CCardHeader>
              <BasicList listFields={listFields} data={data} />
            </CCard>
            <CCard>
              <CCardHeader>Historia</CCardHeader>
              <CCardBody>
                <HistoryList data={data.orderHistories} />
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg={9}>
            <CSmartTable
              items={data?.products}
              loading={isFetching}
              {...tableProps(navigate, details, setDetails)}
            />
          </CCol>
        </CRow>
      </CContainer>
      {passData && (
        <OrderPassModal
          visible={passModalVisible}
          setVisible={setPassModalVisible}
          data={passData}
          orderNumber={data.id}
        />
      )}
    </>
  )
}

export default Order
