import React from 'react'
import { CButton } from '@coreui/react-pro'

const columns = [
  {
    key: 'codeId',
    label: 'ID',
  },
  {
    key: 'product',
    label: 'Produkt',
  },
  {
    key: 'delivery',
    label: 'Dostawa',
  },
  {
    key: 'order',
    label: 'Zamówienie',
  },
  {
    key: 'shopOrder',
    label: 'Zamówienie z Ecommerce',
  },
]

export const searchCodeTable = (navigate) => {
  return {
    columns: columns,
    scopedColumns: {
      product: ({ product }) => <td>{product.name}</td>,
      delivery: ({ delivery }) => (
        <td>
          {delivery ? (
            <>
              <div className="d-inline">{`JK-DELIVERY-${String(delivery.id).padStart(
                5,
                '0'
              )}`}</div>
              <CButton
                color="primary"
                size="sm"
                className="mx-2 block"
                onClick={() => navigate('/dostawa/' + delivery.id)}
              >
                Zobacz
              </CButton>
            </>
          ) : (
            <span>-</span>
          )}
        </td>
      ),
      order: ({ order }) => (
        <td>
          {order ? (
            <>
              <div className="d-inline">{`JK-ORDER-${String(order.id).padStart(5, '0')}`}</div>
              <CButton
                color="primary"
                size="sm"
                className="mx-2 block"
                onClick={() => navigate('/zamowienie/' + order.id)}
              >
                Zobacz
              </CButton>
            </>
          ) : (
            <span>-</span>
          )}
        </td>
      ),
      shopOrder: ({ shopOrder }) => (
        <td>
          {shopOrder ? (
            <div className="d-inline">{`JK-ORDER-${String(shopOrder.id).padStart(5, '0')}`}</div>
          ) : (
            <span>-</span>
          )}
        </td>
      ),
    },
    clickableRows: false,
    columnSorter: false,

    tableProps: {
      striped: true,
      hover: true,
    },
  }
}
